#root {
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  margin: 0;
  padding: 0;
  text-align: center;
  align-self: flex-start;
  background-color: #fff;
  color: #000;
  min-height: 100vh;
}

.ant-layout-header {
  color: #fff;
  height: auto;
}

#login {
  margin: 1rem;
}

.header-greeting {
  background-color: #fff;
}

/* gray-theme */
.ant-layout-sider.gray-theme {
  background-color: #666;
}
.gray-theme .ant-list-header,
.gray-theme .ant-list-footer {
  background-color: #444;
  color: #fff;
}

/* ************** */
div::-webkit-scrollbar {
  width: 0.75rem;
  /* width of the entire scrollbar */
}

div::-webkit-scrollbar-track {
  background: #ddd;
  /* color of the tracking area */
}

div::-webkit-scrollbar-thumb {
  background-color: #bbb;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid #aaa;
  /* creates padding around scroll thumb */
}

section.floater-box {
  text-align: left;
  width: 30rem;
  min-height: 10rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin: 0.75rem;
  display: inline-block;
  position: relative;
  vertical-align: top;
}

section.profileSection {
  min-height: 10rem;
  border: 1px solid #333;
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin: 0.75rem;
  position: relative;
}

.ant-table .ant-table-thead {
  background-color: darkgray;
}

.ant-table-tbody {
  background-color: gray;
}
