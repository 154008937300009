.Tic-Tac-Toe-Square {
  width: 5rem;
  height: 5rem;
  background-color: #333;
  margin-top: 0.5rem;
  z-index: 1000;
}

.Oos {
  padding: 1.4rem;
  border: 4px solid #ddd;
  border-radius: 3rem;
  width: 1.5rem;
  height: 1.5rem;
  margin: auto;
  display: block;
  position: relative;
  top: 12px;
}

.board {
  display: block;
  position: relative;
}

.winnerRow {
  width: 100%;
  height: 0.5rem;
  background-color: green;
  border-radius: 0.5rem;
  display: block;
  position: absolute;
  z-index: 100;
}

.winA {
  top: 13.75rem;
}

.winB {
  top: 8.25rem;
}

.winC {
  top: 2.75rem;
}

.win1 {
  transform: rotate(90deg);
  top: 8.25rem;
  right: 5.5rem;
}

.win2 {
  transform: rotate(90deg);
  top: 8.25rem;
}
.win3 {
  transform: rotate(90deg);
  top: 8.25rem;
  left: 5.5rem;
}

.winDia1 {
  transform: rotate(45deg);
  top: 8.25rem;
}

.winDia2 {
  transform: rotate(135deg);
  top: 8.25rem;
}

.player {
  width: 10rem;
  padding-top: 1rem;
}
